<template>
    <div>
        <div v-loading="loading">
            <div class="order-card" v-if="$has('506')">
                <div class="header-title">
                    <div class="blue"></div>
                    <div class="title">订阅收入统计</div>
                </div>
                <div class="card-main">
                    <div class="card-main-item">
                        <div class="card-info">
                            <img src="@/assets/images/person/customer/icon1.png" alt="">
                            <div class="info">
                                <div class="info-tit">订阅收入 (元)</div>
                                <div class="info-price">{{income.all.money||'0'}}</div>
                                <div class="info-num">订阅次数 <span>{{income.all.count||'0'}}</span></div>
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="card-main-item">
                        <div class="card-info">
                            <img src="@/assets/images/person/customer/icon2.png" alt="">
                            <div class="info">
                                <div class="info-tit">订阅收入 (元)</div>
                                <div class="info-price">{{income.month.money||'0'}}</div>
                                <div class="info-num">订阅次数 <span>{{income.month.count||'0'}}</span></div>
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="card-main-item">
                        <div class="card-info">
                            <img src="@/assets/images/person/customer/icon3.png" alt="">
                            <div class="info">
                                <div class="info-tit">订阅收入 (元)</div>
                                <div class="info-price">{{income.season.money||'0'}}</div>
                                <div class="info-num">订阅次数 <span>{{income.season.count||'0'}}</span></div>
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="card-main-item">
                        <div class="card-info">
                            <img src="@/assets/images/person/customer/icon4.png" alt="">
                            <div class="info">
                                <div class="info-tit">订阅收入 (元)</div>
                                <div class="info-price">{{income.year.money||'0'}}</div>
                                <div class="info-num">订阅次数 <span>{{income.year.count||'0'}}</span></div>
                            </div>
                        </div>
                        <div class="line"></div>
                    </div>
                    <div class="card-main-item">
                        <div id="rightAchart" class="right_echart" v-loading='mReight_loading'></div>
                    </div>
                </div>
            </div>
            <div class="order-card" v-if="$has('506')">
                <div class="header-title">
                    <div class="blue"></div>
                    <div class="title">客户充值统计</div>
                </div>
                <div class="pay-main">
                    <div class="pay-main-item one">
                        <div class="tit">下单客户数据统计</div>
                        <div class="pay-main-flx">
                            <div class="item w50">
                                <img src="@/assets/images/person/customer/icon5.png" alt="">
                                <div class="pay-info">
                                    <div class="name">
                                        下单客户数
                                    </div>
                                    <div class="number">
                                        {{income.order.account_num||'0'}}
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="item w50">
                                <img src="@/assets/images/person/customer/icon6.png" alt="">
                                <div class="pay-info">
                                    <div class="name">
                                        订单数
                                    </div>
                                    <div class="number">
                                        {{income.order.o_num||'0'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pay-main-item two">
                        <div class="tit">人工充值数据统计</div>
                        <div class="pay-main-flx">
                            <div class="item w25">
                                <img src="@/assets/images/person/customer/icon7.png" alt="">
                                <div class="pay-info">
                                    <div class="name">
                                        累计钻石充值(钻)
                                    </div>
                                    <div class="number">
                                        {{income.down_pay.downline_diamond||'0'}}
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="item w25">
                                <img src="@/assets/images/person/customer/icon8.png" alt="">
                                <div class="pay-info">
                                    <div class="name">
                                        累计时长充值(分)
                                    </div>
                                    <div class="number">
                                        {{income.down_pay.downline_video_duration||'0'}}
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="item w25">
                                <img src="@/assets/images/person/customer/icon9.png" alt="">
                                <div class="pay-info">
                                    <div class="name">
                                        训练次数充值(次)
                                    </div>
                                    <div class="number">
                                        {{income.down_pay.downline_advanced_cust||'0'}}
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="item w25">
                                <img src="@/assets/images/person/customer/icon10.png" alt="">
                                <div class="pay-info">
                                    <div class="name">
                                        合同金额(元)
                                    </div>
                                    <div class="number red">
                                        {{income.down_pay.downline_money||'0'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pay-main-item three">
                        <div class="tit">线上充值数据统计</div>
                        <div class="pay-main-flx">
                            <div class="item w50">
                                <img src="@/assets/images/person/customer/icon7.png" alt="">
                                <div class="pay-info">
                                    <div class="name">
                                        累计钻石充值(钻)
                                    </div>
                                    <div class="number">
                                        {{income.online_pay.online_diamond||'0'}}
                                    </div>
                                </div>
                            </div>
                            <div class="line"></div>
                            <div class="item w50">
                                <img src="@/assets/images/person/customer/icon10.png" alt="">
                                <div class="pay-info">
                                    <div class="name">
                                        合同金额(元)
                                    </div>
                                    <div class="number red">
                                        {{income.online_pay.online_money||'0'}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <order-list :id="id" :pay_source="pay_source" :product_category="product_category" :order_source="order_source"></order-list>
    </div>
</template>

<script>
import * as echarts from 'echarts';
import OrderList from './order.vue'//空数据
export default {
    components: { OrderList },
    props: {
        id: String,
        pay_source: Array,   //充值来源
        product_category: Array,  //商品分类
        order_source: Array,  //订单来源
    },
    data() {
        return {
            mReight_loading: false,
            loading: false,
            income: {
                "all": {
                    "count": 0,
                    "money": 0
                },
                "year": {
                    "count": 0,
                    "money": 0
                },
                "season": {
                    "count": 0,
                    "money": 0
                },
                "month": {
                    "count": 0,
                    "money": 0
                },
                "order": {
                    "o_num": 1,
                    "account_num": 1
                },
                "down_pay": {
                    "downline_diamond": 0,
                    "downline_video_duration": 0,
                    "downline_advanced_cust": 0,
                    "downline_money": 0
                },
                "online_pay": {
                    "online_money": 0,
                    "online_diamond": 0
                }
            },
            chartData: [
                { value: 0, name: "月度套餐" },
                { value: 0, name: "季度套餐" },
                { value: 0, name: "年度套餐" },
            ],
            type: '1',
            time_period: '',
            mobile: '',
        };
    },
    created() {
        this.getData()
    },
    mounted() {

    },
    methods: {
        getData() {
            if (!this.$has('507')) {
                return
            }
            let params = {
                id: this.id,
                type: this.type,
                time_period: this.time_period,
                mobile: this.mobile,
            }
            this.loading = true
            this.$personApi.statIncome(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.income = res.result
                    this.chartData[0].value = res.result.month.money
                    this.chartData[1].value = res.result.season.money
                    this.chartData[2].value = res.result.year.money
                    this.rightEchart()
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        changeData(val) {
            this.type = val
            this.getData()
        },
        changeTime(val) {
            if (val) {
                this.type = '4'
                this.time_period = val
            } else {
                this.type = '1'
                this.time_period = ''
            }
            this.getData()
        },
        changeMobile(val) {
            this.mobile = val
            this.getData()
        },
        // 统计图右侧
        rightEchart() {
            var that = this
            that.rightAchart = echarts.init(document.getElementById("rightAchart"));
            const option = {
                tooltip: {
                    trigger: 'item',
                },
                legend: {
                    orient: 'vertical',
                    right: '20',
                    top: 'center',
                    itemWidth: 8,
                    itemHeight: 8,
                    textStyle: {
                        fontSize: 12,
                        color: '#666666'
                    },
                },
                color: ['#2E4BF2', '#FF5F5F', '#32DFFF'],
                series: [{
                    name: '用户数',
                    type: 'pie',
                    center: ['30%', '50%'], // 饼图的圆心坐标
                    radius: ['45%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: this.chartData
                }]
            };
            that.rightAchart.setOption(option);
        },
    },
};
</script>

<style scoped lang="scss">
.right_echart {
    width: 100%;
    height: 100px;
}
.order-card {
    width: 100%;
    padding: 20px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    margin-top: 20px;
    .header-title {
        display: flex;
        align-items: center;
        .blue {
            width: 5px;
            height: 18px;
            background: $blueColor1;
            border-radius: 3px;
        }
        .title {
            padding-left: 10px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
        }
    }
    .card-main {
        width: 100%;
        height: 134px;
        background: #f5f9ff;
        border-radius: 10px;
        margin-top: 20px;
        display: flex;
        align-items: center;

        .card-main-item {
            position: relative;
            width: 19.5%;
            padding: 0 0 0 40px;
            height: 94px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .card-info {
                display: flex;
                align-items: center;
                img {
                    width: 40px;
                    height: 40px;
                }
                .info {
                    margin-left: 20px;
                    .info-tit {
                        font-size: 12px;
                        color: #666666;
                        line-height: 17px;
                    }
                    .info-price {
                        font-size: 24px;
                        font-weight: 500;
                        color: #333333;
                        line-height: 33px;
                        padding-top: 5px;
                    }
                    .info-num {
                        font-size: 14px;
                        color: #333333;
                        line-height: 20px;
                        padding-top: 3px;
                        span {
                            color: #ff7432;
                        }
                    }
                }
            }

            .line {
                width: 1px;
                height: 67px;
                background: #d9d9d9;
            }
        }
    }
    .pay-main {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .pay-main-item {
            height: 136px;
            background: #f5f9ff;
            border-radius: 10px;
            padding: 20px 0;
            .tit {
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 20px;
                padding-left: 20px;
            }
            .pay-main-flx {
                display: flex;
                align-items: center;
                margin-top: 28px;
                .item {
                    height: 48px;
                    padding-left: 30px;
                    display: flex;
                    align-items: center;
                    img {
                        width: 40px;
                        height: 40px;
                    }
                    .pay-info {
                        padding-left: 20px;
                        .name {
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #666666;
                            line-height: 17px;
                        }
                        .number {
                            font-size: 24px;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #333333;
                            line-height: 33px;
                            padding-top: 5px;
                        }
                        .red {
                            color: #ff5f5f;
                        }
                    }
                }
                .line {
                    width: 1px;
                    height: 48px;
                    background: #d9d9d9;
                }
                .w25 {
                    width: 25%;
                }
                .w50 {
                    width: 50%;
                }
            }
        }
        .pay-main-item.one {
            width: 24%;
        }
        .pay-main-item.two {
            width: 50%;
            margin: 0 20px;
        }
        .pay-main-item.three {
            width: 26%;
        }
    }
}
</style>
