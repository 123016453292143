<template>
    <div>
        <div class="con_bottom sec_wrap">
            <div class="content1 p20">
                <el-form ref="params" :model="params" :inline="true" label-position="left" size='small'>
                    <el-form-item label="">
                        <el-button size="small" class="btnBgColor_blue" @click="openAdd" v-if="$has('502')"><i class="el-icon-plus"></i> 新增客户</el-button>
                    </el-form-item>
                    <el-form-item label="客户类型" props="source">
                        <el-select v-model="params.type" placeholder="请选择客户类型" clearable @change="search">
                            <el-option v-for="item in source_list" :key="item.value" :label="item.label" :value="item.value" :clearable="true">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="" props="mobile">
                        <el-input size="small" v-model="params.mobile" @keyup.enter.native="search" @clear='search' clearable prefix-icon="el-icon-search" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="" props="nickname">
                        <el-input size="small" v-model="params.nickname" @keyup.enter.native="search" @clear='search' clearable prefix-icon="el-icon-search" placeholder="请输入客户名称"></el-input>
                    </el-form-item>
                    <el-form-item label="" props="agency_mobile">
                        <el-input size="small" v-model="params.agency_mobile" @keyup.enter.native="search" @clear='search' clearable prefix-icon="el-icon-search" placeholder="请输入所属代理商手机号"></el-input>
                    </el-form-item>
                    <el-form-item label="绑定代理时间" style="margin-right: 100px;">
                        <el-date-picker v-model="date" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" value-format="yyyy-MM-dd" @change="changeData" clearable>
                        </el-date-picker>
                    </el-form-item>
                </el-form>
                <el-button size="small" class="leading-out btnBgColor_blue_empty_no" @click="leadingOut">导出</el-button>
                <div class="table_wrap">
                    <el-table key="first" :data="list" v-loading="loading" style="width: 100%" header-cell-class-name="table-header" cell-class-name="cell-class" @sort-change="changeTableSort" @selection-change="handleSelectionChange">
                        <template slot="empty">
                            <empty-data img='no_img_b.png' text='暂无数据'></empty-data>
                        </template>
                        <el-table-column type="selection" align="center" width="55" fixed="left">
                        </el-table-column>
                        <el-table-column prop="id" label="客户ID" fixed="left">
                        </el-table-column>
                        <el-table-column label="客户名称" prop="nickname" fixed="left" width="140">
                            <template slot-scope="scope">
                                <p>
                                    <span class="label-box" v-if="scope.row.corp_id>0">企业</span>
                                    <span class="label-box" v-else>个人</span>
                                    <span v-if="$has('501')" @click="openEdit(scope.row)" style="cursor: pointer;">{{scope.row.nickname}}</span>
                                    <span v-else style="cursor: pointer;">{{scope.row.nickname}}</span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="memberParentAgency.nickname" label="注册来源">
                        </el-table-column>
                        <el-table-column prop="memberParentAgency.mobile" label="所属代理商" width="120">
                        </el-table-column>
                        <el-table-column prop="mobile" label="登录手机号" width="120">
                        </el-table-column>
                        <el-table-column prop="subscribe_name" label="订阅套餐类型" width="120">
                            <template slot-scope="scope">
                                <p style="color: #2E4BF2;cursor: pointer;" @click="openSetMealInfo(scope.row)">{{scope.row.subscribe_name}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="订阅有效期" width="200">
                            <template slot-scope="scope">
                                <p>{{scope.row.subscribe_started || '--'}}</p>
                                <p><span style="padding-right: 4px;">至</span>{{scope.row.subscribe_ended || '--'}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column label="钻石余额">
                            <template slot-scope="scope">
                                <p>
                                    {{scope.row.diamond || '0'}}钻
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="diamond_con" label="钻石总消耗" width="120">
                        </el-table-column>
                        <el-table-column prop="video_duration_text" label="时长余额">
                        </el-table-column>
                        <el-table-column prop="major_cust" label="剩余次数">
                        </el-table-column>
                        <el-table-column prop="buy_mo" label="购买模型个数" width="120">
                        </el-table-column>
                        <el-table-column prop="customize_mo" label="定制模型个数" width="120">
                        </el-table-column>
                        <el-table-column prop="total_works_num" label="作品总数">
                        </el-table-column>
                        <el-table-column prop="avg_works_time" label="平均作品时长" width="120">
                        </el-table-column>
                        <el-table-column prop="create_time" label="账户注册时间" width="160">
                        </el-table-column>
                        <el-table-column prop="agency_time" label="绑定代理时间" width="160" sortable>
                        </el-table-column>
                        <el-table-column prop="memberAgency.contact" label="联系人">
                        </el-table-column>
                        <el-table-column label="是否启用">
                            <template slot-scope="scope">
                                <el-switch v-model="scope.row.status" :disabled="!$has('508')" :active-value="1" :inactive-value="2" @change="changeSwitch($event,scope.row)"></el-switch>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" fixed="right" width="210">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="pay(scope.row.id)" v-if="$has('506')">
                                    充值
                                </el-button>
                                <el-button type="text" size="small" @click="toInfo(scope.row.id)">
                                    明细
                                </el-button>
                                <el-button type="text" size="small" @click="unbinding(scope.row.id)" v-if="$has('504')">
                                    解绑
                                </el-button>
                                <el-button type="text" size="small" @click="openInfo(scope.row.id)" v-if="$has('505')">
                                    查看资质
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="page_box">
                        <paging-page :total='total' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
                    </div>
                </div>
            </div>
        </div>
        <!-- 解绑 -->
        <point-out ref='dialogTip' @close='closeBtn' type='pay' dialogTitle="提示" dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">是否解除和客户名称的绑定关系？</div>
            </div>
        </point-out>
        <!-- 订阅详情 -->
        <div class="zc_dialog_box" v-show="dialogTableVisible">
            <div class="table_wrap">
                <el-dialog title="订阅详情" :visible.sync="dialogTableVisible" width="962px">
                    <el-table key="table" :data="gridData" header-cell-class-name="table-header" cell-class-name="cell-class" :close-on-click-modal="false">
                        <el-table-column property="subscribe_name" label="订阅套餐类型" width="200"></el-table-column>
                        <el-table-column property="name" label="订阅有效期" width="400">
                            <template slot-scope="scope">
                                <p>{{scope.row.subscribe_started}} / {{scope.row.subscribe_ended}}</p>
                            </template>
                        </el-table-column>
                        <el-table-column property="subscribe_status_text" label="订阅状态"></el-table-column>
                        <el-table-column property="subscribe_started" label="购买时间"></el-table-column>
                    </el-table>
                </el-dialog>
            </div>
        </div>
        <add-customer ref="addCustomer" @getList="getList"></add-customer>
        <set-meal-buy ref="setMealBuy" :topup="topup" @getList="getList"></set-meal-buy>
    </div>
</template>

<script>
import EmptyData from '@/components/empty_data/empty_data.vue'//空数据
import PagingPage from '@/components/paging_page/paging_page.vue'//分页
import AddCustomer from '../add_customer/index.vue'//添加客户
import SetMealBuy from '../set_meal_buy/index.vue'//套餐充值
import PointOut from '@/components/point_out/point_out.vue'//解绑

export default {
    components: { EmptyData, PagingPage, AddCustomer, PointOut, SetMealBuy },
    props: {
        source_list: Array,
        topup: Array,
    },
    data() {
        return {
            dialogTableVisible: false,    //订阅详情
            gridData: [],
            params: {
                type: '',
                mobile: '',
                nickname: '',
                agency_mobile: '',
                time_period: '',
                order_field: '',
                order_redirect: '',
            },
            date: [],
            page: 1,
            limit: 10,
            total: 0,
            loading: false,
            list: [],
            multipleSelection: [],
            id: '',
        };
    },
    created() {
        this.getList()
    },
    methods: {
        // 导出
        leadingOut() {
            this.$warMsg('暂未开放')
        },
        // 解绑确定
        closeBtn() {
            let params = {
                id: this.id
            }
            this.$personApi.customerUnbind(params).then(res => {
                if (res.code == 1000) {
                    this.$succMsg(res.message)
                    this.getList()
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 明细
        toInfo(val) {
            this.$emit('changeCurrent', val)
        },
        // 解绑提示
        unbinding(id) {
            this.id = id
            this.$refs.dialogTip.openDialogBtn()
        },
        // 添加
        openAdd() {
            this.$refs.addCustomer.openAdd()
        },
        // 查看资质
        openInfo(id) {
            this.$refs.addCustomer.openInfo(id)
        },
        // 充值
        pay(id) {
            this.$refs.setMealBuy.open(id)
        },
        // 修改客户
        openEdit(val) {
            this.$refs.addCustomer.openEdit(val.id, val.mobile)
        },
        openSetMealInfo(val) {
            this.gridData[0] = val
            this.dialogTableVisible = true;
        },
        // 修改时间
        changeData(val) {
            if (val) {
                this.params.time_period = this.date[0] + '|' + this.date[1]
            } else {
                this.params.time_period = ''
            }
            this.search()
        },
        // 开关状态修改
        changeSwitch(status, val) {
            let obj = {
                status: status,
                id: val.id
            }
            this.$personApi.rechargeStatus(obj).then(res => {
                if (res.code == 1000) {
                    this.$succMsg('状态修改成功')
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        getList() {
            var params = {
                page: this.page,
                limit: this.limit,
                ...this.params
            }
            this.loading = true
            // 充值列表
            this.$personApi.customerList(params).then(res => {
                this.loading = false
                if (res.code == 1000) {
                    this.list = res.result.data
                    this.total = res.result.total
                }
            })

        },

        search() {
            this.page = 1
            this.getList()
        },
        // 表格排序 asc 升序 desc降序
        changeTableSort(column) {
            if (column.order == null) {
                this.params.order_field = ""
                this.params.order_redirect = ""
            } else if (column.order == "ascending") {
                if (column.prop == "agency_time") {
                    this.params.order_field = "agency_time"
                    this.params.order_redirect = "asc"
                }
            } else if (column.order == "descending") {
                if (column.prop == "agency_time") {
                    this.params.order_field = "agency_time"
                    this.params.order_redirect = "desc"
                }
            }
            this.getList()
        },
        sizeChangeBtn(val) {
            this.limit = val
            this.page = 1
            this.getList();
        },
        currentChangeBtn(val) {
            this.page = val;
            this.getList();
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    },
};
</script>

<style scoped lang="scss">
@import "../../index.scss";
.con_bottom {
    margin-top: 20px;
}
.btnBgColor_blue {
    width: 92px;
    height: 32px;
    border-radius: 4px;
    margin-right: 20px;
}
.content1 {
    position: relative;
    .leading-out {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 72px;
        border-radius: 4px;
    }
}
.label-box {
    padding: 1px 6px;
    margin-right: 10px;
    background: #ffece4;
    border-radius: 2px;
    border: 1px solid #ff7432;
    font-size: 12px;
    color: #ff7432;
    text-align: center;
    line-height: 34px;
}
</style>
